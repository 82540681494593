export default{
    data: () => {
        return {
          activModal: true,
          markerPos: {
            lng: "37.16195747913507",
            lat: "36.19849488598441",
          },
          mapModal: false,
          filterDate: [
            { id: 1, label: "كل المحاضر", status: "" },
            { id: 1, label: "المحاضر الحالية", status: "upcoming" },
            { id: 1, label: "المحاضر السابقة", status: "previous" },
          ],
          optionsState: [
            { label: "بناء", value: "S" },
            { label: "بيت", value: "H" },
            { label: "محل", value: "B" },
          ],
          optionsType: [
            { label: "بيع", value: "S" },
            { label: "إستثمار", value: "I" },
            { label: "إجار", value: "R" },
          ],
          longitude: "",
          latitude: "",
          imageSlide: [],
          staticImages: [],
          deleted_images: [],
          deleted_last_images: [],
          video: null,
          lecturer_type: "",
          lecturer_status:"",
          status: "",
          filter: "",
          utils: {
            lecturer_type: "",
            status: "",
            lecturer_status:""
          },
          activModel: false,
          fields: [
            { key: "lecturer_type", label: "المحضر" },
            { key: "status", label: "الحالة" },
            { key: "price", label: "السعر" },
            { key: "phone", label: "رقم تواصل" },
            { key: "space", label: "المساحة" },
            { key: "actions", label: "" },
          ],
          value_3: 20,
          estatesFormSchema: {
            status: {
              component: "FormSelect",
              key: "lecturer_type",
              attrs: {
                id: "status_",
                label: "المحضر",
                rules: "required",
                reduce: (el) => el.value,
                options: [
                  { label: "بناء", value: "B" },
                  { label: "بيت", value: "H" },
                  { label: "محل", value: "S" },
                ],
              },
            },
            lecturer_type: {
              component: "FormSelect",
              key: "status",
              attrs: {
                id: "lecturer_type_",
                label: "الحالة",
                rules: "required",
                reduce: (el) => el.value,
                options: [
                  { label: "بيع", value: "S" },
                  { label: "إستثمار", value: "I" },
                  { label: "إجار", value: "R" },
                ],
              },
            },
            cladding_type: {
              component: "FormInput",
              key: "cladding_type",
              attrs: {
                id: "cladding_type",
                label: "نوع الأثاث",
                rules: "required",
              },
            },
            address: {
              component: "FormInput",
              key: "address",
              attrs: {
                label: "العنوان",
                rules: "required",
              },
            },
    
            description: {
              component: "FormTextarea",
              key: "description",
              attrs: {
                label: "التفاصيل",
                rules: "required",
              },
            },
            price: {
              component: "FormMask",
              key: "price",
              attrs: {
                label: "السعر",
                options: "number",
                rules: "required",
                dir: "ltr",
                placeholder: "100,000",
                
              },
            },
            space: {
              component: "FormInput",
              key: "space",
              attrs: {
                id:"spaase_luctour",
                label: "المساحة",
                rules: "required",
                
                // placeholder: "100",
              },
            },
            long: {
              key: "long",
            },
            number_floors: {
              component: "FormMask",
              key: "number_floors",
              attrs: {
                label: "الطابق",
                options: "number",
                rules: "required",
                dir: "ltr",
              },
            },
            publication_date: {
              component: "FormDatepicker",
              key: "publication_date",
              attrs: {
                id:"publication_date",
                label: "تاريخ النشر",
                rules: "required",
                placeholder: "YY-MM-DD",
              },
            },
            end_publication_date: {
              component: "FormDatepicker",
              key: "end_publication_date",
              attrs: {
                id:"end_publication_date",
                label: "تاريخ نهاية النشر",
                rules: "required",
                placeholder: "YY-MM-DD",
              },
            },
            phone: {
              component: "FormMask",
              key: "phone",
              attrs: {
                options: "phone",
                rules: "required|min:10",
                label: "رقم تواصل",
                dir: "ltr",
              },
            },
    
            images: {
              key: "images",
            },
            video: {
              key: "video",
            },
          },
        };
      },
}